import { useEffect } from 'react';

/**
 * Chrome doesn't cache fonts automatically on a user's machine. We want to use "font-display: optional"
 * to prevent layout shift when swapping fonts, but this strategy often yields fallback fonts if the
 * self-hosted font doesn't get cached. So... service worker... I guess?
 *
 * Updated to include versioning support for cache invalidation on new deployments.
 *
 * @see https://twitter.com/devjmetivier/status/1507069969795846161?s=20&t=hhk5dLGL0u3Ri9f-deWxqw
 * @see https://gomakethings.com/improving-web-font-performance-with-service-workers/
 */
const registerFontCacheServiceWorker = () => {
  window.addEventListener('load', function () {
    // Add a timestamp query parameter to force service worker update on each deployment
    const deployTimestamp = new Date().getTime();

    navigator.serviceWorker.register(`/font-cache-service-worker.js?v=${deployTimestamp}`).then(
      function (registration) {
        if (process.env.NODE_ENV === 'development') {
          console.log('font-cache-service-worker registered via: ', registration.scope);
        }

        // Force update check to ensure latest service worker is used
        registration.update();
      },

      function (err) {
        if (process.env.NODE_ENV === 'development') {
          console.log('font-cache-service-worker registration failed: ', err);
        }
      }
    );
  });
};

export const useFontCacheServiceWorker = () => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      registerFontCacheServiceWorker();
    }
  }, []);
};
